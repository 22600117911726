uniform sampler2D texture1;
uniform float brightness;
uniform float contrast;
uniform float saturation;

varying vec2 vUv;

vec3 AdjustContrastSaturationBrightness(vec3 color, float brightness, float contrast, float saturation) {
    // Ajuste de brillo
    color *= brightness;

    // Ajuste de contraste
    color = (color - 0.5) * contrast + 0.5;

    // Ajuste de saturación
    float avg = (color.r + color.g + color.b) / 3.0;
    color = mix(vec3(avg), color, saturation);

    return color;
}

void main() {
    vec4 texel = texture2D(texture1, vUv);

    // Ajustar el color con brillo, contraste y saturación
    texel.rgb = AdjustContrastSaturationBrightness(texel.rgb, brightness, contrast, saturation);

    gl_FragColor = texel;
}

import '../style.scss'

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GeoTIFF } from 'geotiff';


// import { initSeparadorEffect } from './separadorEffect.js';


import fragment from '../shaders/fragment.glsl'
import vertex from '../shaders/vertex.glsl'

import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger,SplitText);
// import modelo from '/models/bloque Marbella Epic Trail con textura.glb';
// import modelo from '/models/bloque Marbella Epic Trail con textura.glb';

document.addEventListener("DOMContentLoaded", () => {
  // Inicializar efectos separados
  // const separadorElement = document.getElementById('separador');
  // initSeparadorEffect(separadorElement);

});


document.addEventListener("DOMContentLoaded", () => {
  // Seleccionar todos los elementos con la clase 'separador'
  const separadorElements = document.querySelectorAll('#separador');

  // Aplicar el efecto a cada uno de ellos
  separadorElements.forEach((element) => {
      initSeparadorEffect(element);
  });
});



let currentRef = null;
let despZ = 2.5

const w = window.innerWidth;
const h = window.innerHeight;

const container = document.getElementById('container');
// console.log(container)
const scene = new THREE.Scene();
scene.fog = new THREE.FogExp2(0x03544e, 0.01)
// Fondo negro
scene.background = new THREE.Color(0x000000);

const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
// let camPos = { x: 10, y: 5, z: 5 }
let camPos = { x: 0, y: 0, z: 2 }
camera.position.set(camPos.x, camPos.y, camPos.z);
camera.lookAt(0, 0, 0);

const cameraSettings = {
    posX: camera.position.x,
    posY: camera.position.y,
    posZ: camera.position.z
};
let initialCameraX = camera.position.x
let initialCameraY = camera.position.y
let initialCameraZ = camera.position.z


const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
renderer.setSize(w, h);
// renderer.setClearColor(0x000000, 0); // Transparencia total
renderer.setClearColor(scene.fog.color, 0); // Transparencia total


renderer.toneMapping = THREE.ACESFilmicToneMapping;  // Opcional, para mejorar el tonemapping
renderer.outputEncoding = THREE.sRGBEncoding;

renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
// renderer.toneMappingExposure = 1;
container.appendChild(renderer.domElement)

const rgbeLoader = new RGBELoader();

let hdrTexture = null;

rgbeLoader.load(new URL('/src/assets/textures/royal_esplanade_1k.hdr', import.meta.url).href, function(texture) {

  texture.mapping = THREE.EquirectangularReflectionMapping;

  // Configura el mapa de entorno para la escena
  scene.environment = texture;
  // scene.background = texture;

  // Si quieres aplicar el HDR a un material específico del modelo cargado:
  // Supón que ya tienes el modelo cargado en `model`:
  model.traverse((node) => {
      if (node.isMesh && node.material && node.material.isMeshStandardMaterial) {
          node.material.envMap = texture;
          node.material.needsUpdate = true;
      }
  });

  hdrTexture = texture;  // Hacemos la textura accesible para usarla luego

});


const controls = new OrbitControls( camera, renderer.domElement );

controls.enableDamping = true; // Habilita la amortiguación
controls.dampingFactor = 0.05; // Ajusta este valor según lo necesites
controls.update()

const resize = () => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  renderer.setSize(viewportWidth, viewportHeight);
  camera.aspect = viewportWidth / viewportHeight;
  camera.updateProjectionMatrix();

  loadModel(); // Recargar el modelo
};

window.addEventListener("resize", resize);

// Carga del modelo ------------------------------------------
// const loader = new GLTFLoader().setPath('/models/modelo/');
// loader.load('bloque Marbella Epic Trail con textura1.glb', function (gltf) {
// 	const mesh = gltf.scene
// 	mesh.position.set(0, 0, 0)
// 	mesh.rotateY(-20)
// 	scene.add(mesh);
// }, undefined, function (error) {
//   console.error(error);
// });

let model = null;

function loadModel() {
    const loader = new GLTFLoader();

    // loader.load(new URL('../assets/models/cruz de juanar.glb', import.meta.url).href, function (gltf) {
      loader.load(new URL('../assets/models/La Concha mallado.glb', import.meta.url).href, function (gltf) {

        // Código para manejar el modelo cargado
   
        if (model) {
            scene.remove(model); // Eliminar el modelo anterior si ya existe
        }
        model = gltf.scene;
        scene.add(model);

        model.traverse((node) => {
            if (node.isMesh) {
              node.material.envMap = hdrTexture ;
              node.material.needsUpdate = true;
            }
        });

        renderer.outputEncoding = THREE.sRGBEncoding;


        // model.scale.set(.25, .25, .25); // Ajustar la escala según sea necesario
        // model.scale.set(.25, .25, .25); // Ajustar la escala según sea necesario

        const viewportWidth = window.innerWidth;
        const xPosition = viewportWidth / 1500; // Ajusta según tus necesidades
        model.position.set(0, -.5, 0); // Ajustar la posición según el tamaño del viewport
        model.rotation.y -= .5; // Ajusta este valor para cambiar la velocidad de rotación

        
      // GSAP animation code inside the load function
      let o = { a: 0 };
      let initialZ = model.position.z;
      let initialYmodel = model.position.y
      let initialYcam = camera.position.y

      // gsap.to(o, {
      //     a: 1,
      //     scrollTrigger: {
      //         trigger: '.wrap',
      //         // start: "top top",
      //         // end: "bottom bottom",
      //         // snap: 1/(3 - 1),
      //         onUpdate: (self) => {
      //           // console.log(self.progress)
      //           model.rotation.y = -0.5 * Math.PI * self.progress;
      //           model.position.y = initialYmodel - .5 * self.progress;
      //           // model.position.z = initialZ - 0.5 * self.progress;
      //           mount.rotation.y = -0.5 * Math.PI * self.progress;
      //           mount.position.z = initialZ - 0.5 * self.progress;       
                
      //           camera.position.y = initialYcam - .5 * self.progress;
      //           // console.log(camera.position.y)
      //         }
      //     }
      // });
  });
}
// Geometría del plano ------------------------------------------
const groundGeometry = new THREE.PlaneGeometry(20, 20, 32, 32); // Plano más grande para la sombra
groundGeometry.rotateX(-Math.PI / 2)
// Material para aplicar al plano (color uniforme) ------------------------------------------
const groundMaterial = new THREE.MeshStandardMaterial({
    color: 0x555555, // Color gris oscuro
    side: THREE.DoubleSide,
    envMapIntensity: 0, // Desactiva la influencia del HDR
    roughness: 1, // Totalmente mate
    metalness: 0, // No metálico
});

// const groundMesh = new THREE.Mesh(groundGeometry, groundMaterial)
// groundMesh.position.y = -despZ
// scene.add(groundMesh);

// Iluminación ------------------------------------------

// Foco (SpotLight) para iluminación circular en el plano
const spotLight = new THREE.SpotLight(0xffffff, 1000, 5, 30, 0.5); // Aumenta la intensidad
spotLight.position.set(0, -.5, 0); // Directamente arriba del modelo
scene.add(spotLight);

// model.position.set(0, -0.3, 1); // Ajustar la posición según el tamaño del viewport

spotLight.target.position.set(0, 0, 0); // Asegúrate de que apunta al modelo
scene.add(spotLight.target);

// const spotLightHelper = new THREE.SpotLightHelper(spotLight);
// scene.add(spotLightHelper);

// // Luz de ambiente para iluminación general
const ambientLight = new THREE.AmbientLight(0xffffff, 0.2); // Incrementa la intensidad
scene.add(ambientLight);

const fillLight = new THREE.PointLight(0xffffff, 10.2, 50);
fillLight.position.set(2, 2, 2); // Posicionada al lado del modelo
scene.add(fillLight);



const mountLoader = new THREE.TextureLoader();
const mountHeight = mountLoader.load(new URL('/src/assets/textures/height.jpg', import.meta.url).href);
const mountTexture = mountLoader.load(new URL('/src/assets/textures/texture.jpg', import.meta.url).href);
const mountAlpha = mountLoader.load(new URL('/src/assets/textures/alpha.png', import.meta.url).href);


// Geometría del plano para el monte ------------------------------------------
const mountGeometry = new THREE.PlaneGeometry(30, 30, 64, 64); // Plano más grande para la sombra
mountGeometry.rotateX(-Math.PI / 2)
mountGeometry.rotateY(0.3 * Math.PI)

// Material para aplicar al plano (color uniforme) ------------------------------------------
// const mountMaterial = new THREE.MeshStandardMaterial({
//     uniforms: {
//         texture1: { type: 't', value: mountTexture }, // La textura que has cargado
//         brightness: { type: 'f', value: 1.2 }, // Ajuste de brillo
//         contrast: { type: 'f', value: 1.5 }, // Ajuste de contraste
//         saturation: { type: 'f', value: 1.2 } // Ajuste de saturación
//     },
//     color: 0xffffff,
//     map: mountTexture,
//     displacementMap: mountHeight,
//     displacementScale: 5,
//     alphaMap: mountAlpha,
//     side: THREE.DoubleSide,
//     envMapIntensity: 0, // Desactiva la influencia del HDR
//     roughness: 1, // Totalmente mate
//     metalness: 0, // No metálico
//     vertexShader: vertex,
//     fragmentShader: fragment
  
// });

// const mount = new THREE.Mesh(mountGeometry, mountMaterial)
// mount.position.y = -4
// scene.add(mount)



const mountMaterial = new THREE.ShaderMaterial({
  uniforms: {
      texture1: { type: 't', value: mountTexture }, // La textura principal
      displacementMap: { type: 't', value: mountHeight }, // Mapa de desplazamiento
      displacementScale: { type: 'f', value: 5 }, // Escala de desplazamiento
      brightness: { type: 'f', value: 1. }, // Ajuste de brillo
      contrast: { type: 'f', value: 1.2 }, // Ajuste de contraste
      saturation: { type: 'f', value: 1.1 }, // Ajuste de saturación
  },
  vertexShader: vertex,
  fragmentShader: fragment,
  side: THREE.DoubleSide, // Mantener propiedades adicionales
});

const mount = new THREE.Mesh(mountGeometry, mountMaterial);
mount.scale.set(.5, .5, .5); // Escala x2 en todos los ejes
mount.position.set(0, -3, 0); // Posicionada al lado del modelo
mount.rotation.y = -80 * (Math.PI / 180); // 45 grados en radianes
// mount.position.y = -4;
scene.add(mount);


gsap.registerPlugin(ScrollTrigger);

// document.querySelectorAll('.section').forEach(section => {
//   console.log(section)
//   ScrollTrigger.create({
//     trigger: section,
//     start: "70% 60%",
//     end: "80% 20%",
//     pin: true,
//     scrub: true,
//     toggleClass: "sticky",  // Agrega la clase 'sticky' cuando entra y la quita cuando sale
//     markers: false
//   });
// });


document.querySelector('.back-to-top').addEventListener('click', function(e) {
  e.preventDefault(); // Prevenir el comportamiento normal del enlace
  window.scrollTo({
    top: 0,        // Desplazarse al principio de la página
    behavior: 'smooth' // Hacerlo suavemente
  });
});



// Bucle principal ------------------------------------------
let time = 0; // Este será nuestro contador para la función seno
let amplitude = .4; // Amplitud del movimiento (ajustable según el rango de movimiento que desees)
let frequency = 0.001; // Frecuencia de la oscilación (ajustable para cambiar la velocidad)
const animate = () => {
  // controls.update();
  let scrollPos = window.scrollY || document.documentElement.scrollTop; // Obtén la posición de scroll

  if (model && scrollPos > 500) { // Comienza la rotación después de 500px de scroll
    // model.rotation.y -= 0.001; // Ajusta este valor para cambiar la velocidad de rotación
    let offset = amplitude * Math.sin(frequency * time); // Movimiento sinusoidal
    camera.position.x = initialCameraX - offset*.01;
    camera.position.y = initialCameraY - offset*.1;
    camera.position.z = initialCameraZ + offset;
    model.rotation.y -= offset*.01; // Ajusta este valor para cambiar la velocidad de rotación
    time += 1;

  }

  renderer.render(scene, camera);
  requestAnimationFrame(animate);
};

renderer.domElement.addEventListener('mousedown', () => {
  console.log('Mouse down event detected');
});

loadModel()
animate();


// funcion para controlar la barra superior
// script.js

// script.js

let lastScroll = 0;
const header = document.querySelector('.header');
let ticking = false;

window.addEventListener('scroll', () => {
  if (!ticking) {
    window.requestAnimationFrame(() => {
      const currentScroll = window.pageYOffset;

      if (currentScroll > lastScroll && currentScroll > header.offsetHeight){
        // Scroll hacia abajo - ocultar el header con GSAP
        gsap.to(header, { y: -header.offsetHeight, duration: 0.3, ease: "power2.out" });
      } else {
        // Scroll hacia arriba - mostrar el header con GSAP
        gsap.to(header, { y: 0, duration: 0.3, ease: "power2.out" });
      }

      lastScroll = currentScroll;
      ticking = false;
    });

    ticking = true;
  }
});

document.addEventListener("DOMContentLoaded", function() {

  // menú hamburguesa
  const menuHamburguesa = document.getElementById("menu-hamburguesa");
  const overlay = document.getElementById("overlay");
  const menuLinks = document.querySelectorAll(".overlay-menu a"); // Selecciona todos los enlaces del menú

  function toggleMenu() {
    menuHamburguesa.classList.toggle("active");

    if (menuHamburguesa.classList.contains("active")) {
      // Mostrar overlay: desplazar desde la derecha hacia el centro
      gsap.to(overlay, { duration: 0.5, x: "100vw", ease: "power3.in" });
    } else {
      // Ocultar overlay: desplazar hacia la derecha fuera de la pantalla
      gsap.to(overlay, { duration: 0.5, x: 0, ease: "power3.out" });
    }
  }

  // Evento de clic en la hamburguesa
  menuHamburguesa.addEventListener("click", toggleMenu);

  // Evento de clic en cada enlace del menú para ocultar el overlay
  menuLinks.forEach(link => {
    link.addEventListener("click", function() {
      if (menuHamburguesa.classList.contains("active")) {
        toggleMenu(); // Oculta el overlay al hacer clic en un enlace del menú
      }
    });
  });

  // boton para abrir el formulario de contacto
  const btnContacto = document.getElementById("btn-contacto");
  const overlayContacto = document.getElementById("overlay2");
  
  if(btnContacto) {
    btnContacto.addEventListener("click", () => {
      overlayContacto.style.display = "block"; // Asegurar que el overlay esté visible
      gsap.to(overlayContacto, { 
        duration: 0.5, 
        x: "100vw",
        y: "8vh",
        ease: "power3.in" 
      });
    });
  }  
  // boton para abrir el formulario de contacto
  const btnCerrarContacto = document.getElementById("btn-cerrar-formulario");
  
  if(btnCerrarContacto) {
    btnCerrarContacto.addEventListener("click", () => {
      overlayContacto.style.display = "block"; // Asegurar que el overlay esté visible
      gsap.to(overlayContacto, { 
        duration: 0.5, 
        x: "-100vw",
        y: "8vh",
        ease: "power3.in" 
      });
    });
  }
  
    // boton para abrir el formulario de inscripciones
    const btnInscripcion = document.getElementById("btn-inscripcion");
    if (btnInscripcion) {
      btnInscripcion.addEventListener('click', () => {
        window.location.href = 'inscripciones.html';  // Redirige a la nueva página
      });
    }
  
});




window.onload = () => {
  var faq_lines = document.querySelectorAll(".faq_linea_titulo");

  faq_lines.forEach(function(line) {
    line.addEventListener("click", function(e) {
      const respuesta = this.nextElementSibling;
      const verticalLine = this.querySelector('.vertical');

      if (!this.classList.contains('active')) {
        // Cerrar todas las respuestas y quitar la clase active
        faq_lines.forEach(function(otherLine) {
          const otherRespuesta = otherLine.nextElementSibling;
          const otherVerticalLine = otherLine.querySelector('.vertical');
          otherLine.classList.remove('active');
          gsap.to(otherVerticalLine, {rotate: 0, duration: 0.3});
          gsap.to(otherRespuesta, {height: 0, opacity: 0, duration: 0.5, ease: "power2.inOut"});
          otherRespuesta.classList.add('faq_ocultar');
        });

        // Expandir la respuesta seleccionada
        this.classList.add('active');
        gsap.to(verticalLine, {rotate: 90, duration: 0.3});
        gsap.to(respuesta, {height: "auto", opacity: 1, duration: 0.5, ease: "power2.inOut"});
        respuesta.classList.remove('faq_ocultar');
      } else {
        // Contraer la respuesta seleccionada
        this.classList.remove('active');
        gsap.to(verticalLine, {rotate: 0, duration: 0.3});
        gsap.to(respuesta, {height: 0, opacity: 0, duration: 0.5, ease: "power2.inOut"});
        respuesta.classList.add('faq_ocultar');
      }
    });
  });
}

uniform sampler2D displacementMap;
uniform float displacementScale;

varying vec2 vUv;

void main() {
    vUv = uv;

    // Obtener el valor de desplazamiento del mapa
    vec3 newPosition = position + normal * texture2D(displacementMap, uv).r * displacementScale;

    // Transformar la posición del vértice
    gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
}
